import type {ZudokuConfig, ZudokuPlugin} from "zudoku";
import { LoaderCircle, FileKey2Icon } from "lucide-react";

export const Spinner = ({ size = 16 }: { size?: number }) => (
    <LoaderCircle size={size} className="animate-spin" />
);

import { Button, Head, Link } from "zudoku/components";

export const LandingPage = () => {
  return (
      <section className="">
        <Head>
          <title>My Custom Page</title>
        </Head>
        <div>
          <p>Welcome to MyCustomPage, here take a spinner</p>
        </div>
        <Spinner size={32} />
        <Button>Here is a button</Button>
      </section>
  );
};

/*

const config: ZudokuConfig = {
  plugins:[
    {
      getProfileMenuItems: () => {
        debugger;
        return [
          {
            label: "API Keys",
            path: "/settings/api-keys",
            category: "middle",
            icon: FileKey2Icon,
          },
        ];
      },
      getIdentities: async (context) => {
        debugger;
        return [{
          id: '1',
          label:
              `API v1`,
          authorizeRequest: (request) => {
            debugger;
          }}]
        }
    } as ZudokuPlugin
  ],
  page: {
    pageTitle: "ABC Docs",
    logo: {
      src: {
        light: '/light.png',
        dark: '/dark.png'
      },
    },
    banner: {
      message: "This is a banner that we can add for important messages, it can be dismissed or be non-dismissible, it has few style options - note, info, tip, caution, danger",
      color: 'caution',
      dismissible: true
    }
  },
  topNavigation: [
    { id: "documentation", label: "Documentation" },
    { id: "api", label: "API Reference" },
  ],
  metadata: {
    title: "Vika Docs",
    favicon: "/favicon.ico",
  },
  theme: {
    light: {
      primary: "194, 85%, 61%", // R
      primaryForeground: "194, 85%, 0%", // B
    },
    dark: {
      primary: "120, 100%, 50%", // G
      primaryForeground: "60, 100%, 50%", // Y
    }
  },
  sidebar: {
    documentation: [
      {
        type: "category",
        label: "Getting Started",
        description: "Bla",
        items: ["docs/introduction", "docs/example"],
      },
      {
        type: "doc",
        id: "docs/example1",
        badge: {
            label: "New",
            color: "green",
            placement: "end"
        }
      },
      {
        type: "doc",
        id: "docs/example2",
        badge: {
            label: "Old",
            color: "red",
            placement: "start"
        }
      },
      {
        type: "link",
        href: "/a-custom-page",
        label: "Custom Page",
        badge: {
            label: "Custom",
            color: "yellow",
            placement: "start"
        }
      },
    ],
  },
  redirects: [
      { from: "/", to: "/docs/introduction" },
  ],
  apis: [
    {
      type: "file",
      input: "apis/openapi.json",
      navigationId:"api"
    },
  ],
  docs: {
    files: "/pages/!**!/!*.{md,mdx}",
  },
  customPages: [
    {
      path: "/a-custom-page",
      element: <LandingPage />,
    },
  ],
  mdx: {
    components: {
      Spinner,
      Button,
    },
  },
  vika: {},
};
*/

export default {

    mdx: {
        components: {
            Spinner,
            Button,
        },
    },
};
